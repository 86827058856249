<script lang="ts" setup>
const list = ref()
const isHovered = useElementHover(list)
</script>

<template>
  <ul ref="list" class="cul">
    <slot :is-hovered="isHovered" />
  </ul>
</template>
